angular.module('MyHippoProducer.Services').factory('WorkflowAdapter', function ($log, WorkflowUtil, PolicyService, UserService) {
    const evaluateExpression = (exp, item) => {
        if (!exp) { return exp; }
        const policyInfo = PolicyService.policyInfo;
        const workflowPolicyInfo = WorkflowUtil.getWorkflowObjectInfo(policyInfo);
        WorkflowUtil.extendWithFunctions(workflowPolicyInfo, policyInfoFunctions.get());
        const result = WorkflowUtil.evaluateExpression(exp, workflowPolicyInfo, UserService.getRoles(), {}, item);
        return result === undefined ? false : result;
    };

    const setPropsToEval = (current, newProps) => {
        const evaluateProps = ['readonly', 'edit_readonly', 'visible', 'value', 'values', 'validate', 'validate_message', 'client_default'];
        return evaluateProps.reduce((properties, prop) => {
            if (newProps[prop] !== undefined) {
                properties[prop] = newProps[prop];
            }
            return properties;
        }, _.cloneDeep(current));
    };

    const evaluateAll = (properties, item) => {
        return Object.keys(properties).reduce((evaluated, propKey) => {
            const expression = properties[propKey];
            evaluated[propKey] = evaluateExpression(expression, item);
            return evaluated;
        }, {});
    };

    const defineNewProperty = (obj, key, newProps) => {
        if (!obj.hasOwnProperty(key)) {
            Object.defineProperty(obj, key, newProps);
        }
        return obj;
    };

    const augmentField = (field, key) => {
        const fieldCopy = _.cloneDeep(field);
        let options = evaluateExpression(field.values);
        const methods = {
            isVisible: {
                get: function () { return evaluateExpression(field.visible) !== false; }
            },
            options: {
                get: function () {
                    // Compare the original options and evaluated options
                    // Return new options only if they're different to avoid redigest
                    const newOptions = evaluateExpression(field.values);
                    if (_.isEqual(options, newOptions)) { return options; }
                    return newOptions;
                },
            },
            isReadOnly: {
                get: function () {
                    const readOnly = evaluateExpression(field.readonly);
                    const editReadOnly = evaluateExpression(field.edit_readonly);
                    return editReadOnly || readOnly && editReadOnly === undefined;
                }
            },
            errors: {
                get: function () { return PolicyService.errors[key]; }
            },
            raw: {
                get: function () {
                    if (field.value) {
                        return evaluateExpression(field.value);
                    }

                    return _.get(PolicyService.policyInfo, key);
                },
                set: function (newValue) {
                    const policyInfo = PolicyService.policyInfo;
                    if (field.type === 'number' && !isNaN(newValue)) {
                        newValue = Number(newValue);
                    } else if (field.type === 'boolean' && _.includes(['true', 'false'], newValue)) {
                        newValue = newValue === 'true';
                    }
                    _.set(policyInfo, key, newValue);
                }
            },
            display: {
                get: function () {
                    if (field.values) {
                        const fieldOptions = evaluateExpression(field.values);
                        const value = evaluateExpression(field.value) || _.get(PolicyService.policyInfo, key);
                        return fieldOptions[value];
                    } else if (field.value) {
                        return evaluateExpression(field.value);
                    } else {
                        return _.get(PolicyService.policyInfo, key);
                    }
                },
                set: function (newValue) {
                    const policyInfo = PolicyService.policyInfo;
                    if (field.type === 'number' && !isNaN(newValue)) {
                        newValue = Number(newValue);
                    } else if (field.type === 'boolean' && _.includes(['true', 'false'], newValue)) {
                        newValue = newValue === 'true';
                    }
                    _.set(policyInfo, key, newValue);
                }
            },
            isValid: {
                get: function () {
                    if (field.validate) {
                        return evaluateExpression(field.validate);
                    }
                    return true;
                }
            },
            default: {
                get: function() {
                    return evaluateExpression(field.client_default);
                }
            }
        };

        fieldCopy.getItemDisplay = function (item) {
            if (field.values) {
                const fieldOptions = evaluateExpression(field.values, item);
                const value = evaluateExpression(field.value, item) || _.get(PolicyService.policyInfo, key);
                return fieldOptions[value];
            } else if (field.value) {
                return evaluateExpression(field.value, item);
            } else {
                return _.get(PolicyService.policyInfo, key);
            }
        };

        Object.keys(methods).forEach(key => {
            defineNewProperty(fieldCopy, key, methods[key]);
        });

        return fieldCopy;
    };

    let mapping = {};
    let mappingForPolicyId;
    const onSaveEvaluation = {};
    const getMapping = () => {
        const policyId = PolicyService.policyInfo.id;
        if (!_.isEmpty(mapping) && mappingForPolicyId === policyId) { return mapping; }
        // Generate new mapping if there's a new policy id or if mapping not initialized
        const { pages } = PolicyService.getWorkflow();
        mappingForPolicyId = policyId;
        mapping = pages.reduce((mapping, page) => {
            const { sections, properties } = page;
            const pageKey = properties.key;
            const pageProperties = setPropsToEval({}, properties);
            sections.forEach(({ fields, properties }) => {
                const sectionProperties = setPropsToEval(pageProperties, properties);
                const parentKey = properties.parent_key;
                // Grid Type
                if (properties.type === 'grid' || properties.type === 'limit') {
                    const evaluatedFields = fields.map(field => {
                        const fieldProperties = setPropsToEval(sectionProperties, field);
                        if (field.on_save) {
                            onSaveEvaluation[field.key] = field.on_save;
                        }
                        return _.assign({}, field, evaluateAll(fieldProperties));
                    });
                    mapping[properties.parent_key] = {
                        pageKey,
                        fields: evaluatedFields,
                    };
                } else {
                    const isValidField = (field) => field.format !== 'url';
                    fields.filter(isValidField).forEach(field => {
                        if (!field.key && field.type !== 'button') {
                            $log.warn('no field key', field);
                        }
                        if (field.on_save) {
                            onSaveEvaluation[field.key] = field.on_save;
                        }
                        const key = parentKey ? `${parentKey}.${field.key}` : field.key;
                        if (!mapping[key]) {
                            const inheritedProperties = setPropsToEval(sectionProperties, field);
                            let fieldProps = _.assign({ pageKey }, inheritedProperties, field);
                            if(key === 'effective_date') {
                                _.set(fieldProps, 'pageKey', 'checkout_page');
                            }
                            mapping[key] = augmentField(fieldProps, key);
                        }
                    });
                }
            });
            return mapping;
        }, {});

        return mapping;
    };

    const evaluateOnSaves = () => {
        const expressions = Object.values(onSaveEvaluation);
        expressions.forEach((expression) => {
            evaluateExpression(expression);
        });
    };

    const flattenObject = (obj, prefix = '') => {
        const policyInfo = PolicyService.policyInfo;
        return Object.keys(obj).reduce((acc, k) => {
            const pre = prefix.length ? prefix + '.' : '';
            if (typeof obj[k] === 'object' && !Array.isArray(_.get(policyInfo, pre + k))) {
                Object.assign(acc, flattenObject(obj[k], pre + k));
            } else {
                acc[pre + k] = obj[k];
            }
            return acc;
        }, {});
    };

    const validateUpdates = () => {
        const updates = PolicyService.getUpdates();
        return Object.keys(flattenObject(updates)).reduce((errors, key) => {
            const field = getField(key);
            if (!field.isValid) {
                errors.push({ fields: [key], agent_message: field.validate_message });
            }
            return errors;
        }, []);
    };

    const separateUpdateByPages = () => {
        const updates = PolicyService.getUpdates();
        const flatUpdates = flattenObject(updates);
        const updatesByPage = Object.keys(flatUpdates).reduce((updates, path) => {
            const value = flatUpdates[path];
            const fields = getMapping()[path];
            const updatePath = `${fields.pageKey}.${path}`;
            if(!fields.readonly || !fields.isReadOnly) {
                _.set(updates, updatePath, value);
            }
            return updates;
        }, {});
        return updatesByPage;
    };

    const getPage = (pageKey) => {
        const { pages } = PolicyService.getWorkflow();
        return pages.find(page => page.properties.key === pageKey);
    };

    function getField (path) {
        const field = getMapping()[path];
        if (!field) { console.warn('Missing', path); }
        return field;
    };


    // Sections
    const augmentSection = (section, visibilityKey) => {
        if (section) {
            const parentKey = section.properties.parent_key;
            defineNewProperty(section, 'isVisible', {
                get: function () { return evaluateExpression(section.properties.visible); },
            });

            if (section.properties.type === 'grid') {
                let previousEvalItems;
                let previousItems;

                defineNewProperty(section, 'items', {
                    get: function () {
                        const items = _.get(PolicyService.policyInfo, parentKey, []);
                        const clonedItems = _.cloneDeep(items);

                        if (_.isEqual(items, previousItems)) {
                            return previousEvalItems;
                        }

                        const evaluatedItems = clonedItems.map((item, itemIdx) => {
                            section.fields.forEach((itemFields) => {
                                item[itemFields.key] = augmentField(itemFields, `${parentKey}.${itemIdx}.${itemFields.key}`);
                            });
                            return item;
                        });

                        previousEvalItems = evaluatedItems;
                        previousItems = _.cloneDeep(items);
                        return evaluatedItems;
                    },
                    set: function (newVal) {
                        return _.set(PolicyService.policyInfo, parentKey, newVal);
                    },
                });

                section.addItem = function (item) {
                    const policyInfo = PolicyService.policyInfo;
                    if (!_.get(policyInfo, parentKey)) {
                        _.set(policyInfo, parentKey, []);
                    }

                    const items = _.get(policyInfo, parentKey);
                    items.push(item);
                };

                section.saveItem = function (itemUpdate) {
                    const policyInfo = PolicyService.policyInfo;
                    const items = _.get(policyInfo, parentKey);
                    const itemIdx = items.findIndex(item => item.id === itemUpdate.id);
                    items.splice(itemIdx, 1, itemUpdate);
                };

                section.deleteItem = function (itemId) {
                    const policyInfo = PolicyService.policyInfo;
                    const items = _.get(policyInfo, parentKey);
                    const itemIdx = items.findIndex(item => item.id === itemId);
                    items.splice(itemIdx, 1);
                };

                defineNewProperty(section, 'visibilityKey', {
                    get: function () { return visibilityKey; },
                });
            }

            if (section.properties.type === 'limit') {
                defineNewProperty(section, 'limits', {
                    get: function() {
                        return  _.get(PolicyService.policyInfo, parentKey);
                    },
                    set: function (newVal) {
                        return _.set(PolicyService.policyInfo, parentKey, newVal);
                    },
                });
            }

            if (section.properties.type === 'collapsed' && visibilityKey) {
                section.fields = section.fields.map((field) => {
                    const key = parentKey ? `${parentKey}.${field.key}` : field.key;
                    return augmentField(field, key);
                });
            }
        }
        return section;
    };

    const getSection = (pageKey, sectionKey) => {
        const { sections } = getPage(pageKey);
        const section = sections.find(section => section.properties.key === sectionKey);
        return augmentSection(section);
    };

    const findRelatedSection = (pageKey, fieldKey) => {
        const { sections } = getPage(pageKey);
        const relatedSections = sections.filter(section => {
            const visibleProp = _.get(section, 'properties.visible');
            return typeof visibleProp === 'string' && visibleProp.indexOf(fieldKey) !== -1;
        });
        return relatedSections.map(section => augmentSection(section, fieldKey));
    };

    const getAllFieldsForSection = (pageKey, sectionKey) => {
        const section = getSection(pageKey, sectionKey);
        if (!section) {
            return null;
        }

        const { fields, properties } = section;
        const sectionParentKey = properties.parent_key;
        if (properties.type === 'grid') {
            return getMapping()[sectionParentKey].fields;
        }

        return fields.map(field => {
            const fieldKey = sectionParentKey ? `${sectionParentKey}.${field.key}` : field.key;
            return getField(fieldKey);
        });
    };

    const createActionFn = (actionExpression) => {
        return () => evaluateExpression(actionExpression);
    };

    function pageHasErrors (pages) {
        const errors = PolicyService.errors;
        const pagesWithErrors = Object.keys(errors).reduce((pages, fieldKey) => {
            const field = getField(fieldKey);
            if (field) {
                pages.push(field.pageKey);
            }
            return pages;
        }, []);
        return _.some(pagesWithErrors, key => _.includes(pages, key) || _.includes(pages, key));
    }

    return {
        getField,
        getSection,
        getPage,
        createActionFn,
        evaluateExpression,
        findRelatedSection,
        getAllFieldsForSection,
        separateUpdateByPages,
        validateUpdates,
        pageHasErrors,
        evaluateOnSaves,
    };
});
